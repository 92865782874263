<template>
  <div
    class="rechargeType container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <div class="txt-center">
          <p>
            <svg-icon icon-class="USDT" style="width: 50px; height: 50px" />
          </p>
        </div>
        <van-tabs
          v-model:active="form.type"
          type="card"
          class="noshadow txt-center"
          shrink
        >
          <van-tab title="ERC20" />
          <van-tab title="OMNI" />
          <van-tab title="TRC20" />
        </van-tabs>
        <van-field
          v-model="form.address"
          :label="$t('bank.text13')"
          placeholder="请输入数字货币地址"
          class="mt-10"
        />
        <van-field
          v-model="form.security_password"
          :label="$t('bank.text14')"
          placeholder="请输入安全密码"
          class="mt-10"
        />
        <p class="fz12 txt-left wt90 mg-center con" style="line-height: 25px">
          * {{$t('bank.text15')}}
        </p>
      </van-cell-group>
      <div style="margin-top: 16px">
        <van-button round block type="primary" native-type="submit">
          {{$t('public.submit')}}
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script setup>
import { reactive, toRaw } from "vue";
import { setCoinWallet } from "@/api/user";
import { Toast } from "vant";
import { useRouter } from "vue-router";

const router = useRouter();
const form = reactive({
  type: 0,
  address: "",
  security_password: "",
});
const onSubmit = () => {
  if (form.address == "" || form.security_password == "") {
    Toast.fail("请填写完整");
    return;
  }
  const toastLoading =Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  setCoinWallet(toRaw(form))
    .then(() => {
      Toast.success("操作成功");
      setTimeout(() => {
        router.push("/my");
      }, 1500);
    })
    .catch(() => {
      toastLoading.clear();
    });
};
</script>

<style lang="less" scoped>
.rechargeType {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;
  min-height: 300px;

  .card {
    font-size: 50px;
  }

  .van-cell-group--inset {
    margin: 10px 0;
  }
}
</style>
